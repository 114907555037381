module Client.Page.Admin.GatewayList

open System.Collections.Generic
open Client
open Client.Infrastructure.Api
open Client.Components
open Client.Domain
open Client.Infrastructure
open Client.InfrastructureTypes
open Client.DomainTypes.Msg

open Client.Forms
open Elmish
open Fable.React.Props
open Fulma
open Fulma.Extensions.Wikiki
open Shared
open Shared.Dto
open Shared.Dto.Dto
open Shared.Infrastructure
open Fable.React
open Shared.Dto.Gateways
open Shared.Dto.MySensGateway
open Shared.Dto.User
open Thoth.Elmish

type Refreshable<'a> =
    | NotLoaded
    | Refreshing
    | Refreshed of 'a

type GatewayViewModel = {
    Id: int
    Gateway: MySensGateway
    Status: Refreshable<GatewayStatus option>
}

let private gatewayToViewModel (gateway: IdValue<MySensGateway>) : GatewayViewModel = {
    Id = gateway.Id
    Gateway = gateway.Value
    Status = Refreshable.NotLoaded
}

type DataModel = {
    Gateways: GatewayViewModel list
    Users: UserDto list
    Modal: Gateway.Model option
    RefreshAllGatewayStatsRunning: bool
    TTNRefreshRequestRunning: bool
    TTIRefreshRequestRunning: bool
    Session: UserSession
}

type LoadingModel = {
    Session: UserSession
    Gateways: IdValue<MySensGateway> list option
    Users: UserDto list option
}

let modelLoadingToData (model: LoadingModel) : DataModel option =
    Option.map2
        (fun gateways users -> {
            Gateways = List.map gatewayToViewModel gateways
            Session = model.Session
            Users = users
            Modal = None
            RefreshAllGatewayStatsRunning = false
            TTNRefreshRequestRunning = false
            TTIRefreshRequestRunning = false
        })
        model.Gateways
        model.Users

type Model = Loadable<DataModel, LoadingModel>

let init (session: UserSession) =
    let request = {
        SessionKey = session.SessionKey
        Data = ()
    }

    let cmds =
        Cmd.batch [
            Cmd.OfAsync.perform api.getAllGateways request (GatewayListMsg.GatewaysReceived >> GatewayList)
            Cmd.OfAsync.perform api.getAllUsers request (GatewayListMsg.UsersReceived >> GatewayList)
        ]

    Loadable.Loading {
        Gateways = None
        Session = session
        Users = None
    },
    cmds

let updateRequestRunningModalState (model: DataModel) (requestRunning: bool) =
    let modalModel = Option.get model.Modal

    { model with Modal = Some { modalModel with RequestRunning = requestRunning } }

let private updateRefreshRequestRunning (model: DataModel) (running: bool) = {
    model with
        TTNRefreshRequestRunning = running
        TTIRefreshRequestRunning = running
}

let private replaceGatewayData (gateways: GatewayViewModel list) (updatedGateway: IdValue<MySensGateway>) =
    gateways
    |> List.map (fun gw ->
        if gw.Id = updatedGateway.Id then
            { gw with Gateway = updatedGateway.Value }
        else
            gw
    )

let private updateGatewayViewModel (viewModel: GatewayViewModel) (status: GatewayWithStatus) =
    let newStatus = Refreshable.Refreshed status.Status

    let newGatewayData =
        match status.Location with
        | Some location -> {
            viewModel.Gateway with
                Latitude = Some location.Latitude
                Longitude = Some location.Longitude
          }
        | None -> viewModel.Gateway

    {
        viewModel with
            Status = newStatus
            Gateway = newGatewayData
    }

let private getStatus (stats: IDictionary<string, GatewayWithStatus>) (viewModel: GatewayViewModel) =
    Dict.tryGetValue stats viewModel.Gateway.TtnId

let private updateAllGatewayWithStatus (gateways: GatewayViewModel list) (stats: GatewayWithStatus list) =
    let dict = Dict.ofList (fun g -> g.TtsId, g) stats

    gateways
    |> List.map (fun viewModel -> viewModel, getStatus dict viewModel)
    |> List.map (fun (viewModel, status) ->
        match status with
        | Some status -> updateGatewayViewModel viewModel status
        | None -> viewModel
    )

let update (msg: GatewayListMsg) (model: Model) : Model * Cmd<Msg> =
    match msg, model with
    | GatewayListMsg.GatewaysReceived response, Loadable.Loading loadingModel ->
        match response with
        | AuthenticatedResponse.Ok gateways ->
            let updatedLoadingModel = { loadingModel with Gateways = Some gateways }

            let newModel =
                modelLoadingToData updatedLoadingModel
                |> Option.map Loadable.Data
                |> Option.defaultValue (Loadable.Loading updatedLoadingModel)

            newModel, Cmd.none
        | AuthenticatedResponse.Error _ -> Loadable.Error "Fehler beim Laden der Gateways", Cmd.none
    | GatewayListMsg.UsersReceived maybeUsers, Loadable.Loading loadingModel ->
        match maybeUsers with
        | Result.Ok users ->
            let updatedLoadingModel = { loadingModel with Users = Some users }

            let newModel =
                modelLoadingToData updatedLoadingModel
                |> Option.map Loadable.Data
                |> Option.defaultValue (Loadable.Loading updatedLoadingModel)

            newModel, Cmd.none
        | Result.Error _ -> Loadable.Error "Fehler beim Laden der Benutzer", Cmd.none
    | GatewayListMsg.LoadAllGatewayStatus, Loadable.Data dataModel ->
        let ids = dataModel.Gateways |> List.map (fun g -> g.Gateway.TtnId)

        let request: AuthenticatedRequest<string list> = {
            SessionKey = dataModel.Session.SessionKey
            Data = ids
        }

        let cmd =
            Cmd.OfAsync.perform api.loadGatewayStatus request (GatewayListMsg.GatewayStatusReceived >> GatewayList)

        Loadable.Data { dataModel with RefreshAllGatewayStatsRunning = true }, cmd
    | GatewayListMsg.GatewayStatusReceived response, Loadable.Data dataModel ->
        match response with
        | AuthenticatedResponse.Ok gatewayStats ->
            let newModel = {
                dataModel with
                    Gateways = updateAllGatewayWithStatus dataModel.Gateways gatewayStats
                    RefreshAllGatewayStatsRunning = false
            }

            Loadable.Data newModel, Cmd.none
        | AuthenticatedResponse.Error _ ->
            let toastCmd =
                Toast.create "Fehler beim Laden des Status aller Gateways" |> Toast.error

            Loadable.Data { dataModel with RefreshAllGatewayStatsRunning = false }, toastCmd
    | GatewayListMsg.RefreshGatewaysTTN, Loadable.Data data ->
        let cmd =
            Cmd.OfAsync.perform api.refreshGatewaysFromTtn () (GatewayListMsg.GatewaysRefreshed >> GatewayList)

        Loadable.Data { data with TTNRefreshRequestRunning = true }, cmd
    | GatewayListMsg.RefreshGatewaysTTI, Loadable.Data data ->
        let cmd =
            Cmd.OfAsync.perform api.refreshGatewaysFromTti () (GatewayListMsg.GatewaysRefreshed >> GatewayList)

        Loadable.Data { data with TTIRefreshRequestRunning = true }, cmd
    | GatewayListMsg.GatewaysRefreshed successful, Loadable.Data data ->
        if successful then
            let toastCmd =
                Toast.create "Die Gateways wurden erfolgreich aktualisiert" |> Toast.success

            init data.Session |> Cmds.batch toastCmd
        else
            let toastCmd =
                Toast.create "Ein Fehler ist aufgetreten beim aktualisieren der Gateways"
                |> Toast.error

            Loadable.Data(updateRefreshRequestRunning data false), toastCmd


    | GatewayListMsg.CloseModal, Loadable.Data data -> Loadable.Data { data with Modal = None }, Cmd.none
    | GatewayListMsg.OpenModal gateway, Loadable.Data data ->
        Loadable.Data { data with Modal = Some(Gateway.init data.Users gateway) }, Cmd.none
    | GatewayListMsg.GatewayUpdated(gateway, success), Loadable.Data data ->
        if success then
            let toastCmd =
                Toast.create "Der Gateway wurde erfolgreich gespeichert" |> Toast.success

            Loadable.Data {
                data with
                    Gateways = replaceGatewayData data.Gateways gateway
                    Modal = None
            },
            toastCmd
        else
            let toastCmd = Toast.create "Das Speichern ist fehlgeschlagen" |> Toast.error

            Loadable.Data(updateRequestRunningModalState data false), toastCmd
    | GatewayListMsg.UpdateGateway gateway, Loadable.Data data ->
        Loadable.Data(updateRequestRunningModalState data true),
        Cmd.OfAsync.perform
            api.updateGateway
            gateway
            ((fun success -> gateway, success)
             >> GatewayListMsg.GatewayUpdated
             >> GatewayList)
    | _, Loadable.Data data ->
        let result =
            match data.Modal with
            | Some modal -> Gateway.update msg modal |> (fun (modal, cmd) -> Some modal, cmd)
            | None -> None, Cmd.none

        Loadable.Data { data with Modal = fst result }, snd result
    | _, _ -> model, Cmd.none

let maybeFloatToString (maybeFloat: float option) =
    maybeFloat
    |> Option.map (fun float -> float.ToString())
    |> Option.defaultValue ""

let private createStatusTableCell (label: string) (statusDot: StatusDot) (maybeTooltip: string option) =
    td [] [
        div [
            classList [
                (Tooltip.ClassName, true)
                ("device-status", true)
            ]
            match maybeTooltip with
            | Some tooltip -> Tooltip.dataTooltip tooltip
            | None -> ()
        ] [
            p [] [ str label ]
            img [
                Style [ Height "10px" ]
                Src(StatusDot.toImageUrl statusDot)
            ]
        ]
    ]

let private createStatusCellFromStatus (status: GatewayStatus option) : ReactElement =
    match status with
    | Some(Connected connected) ->
        let tooltip =
            sprintf "Seit %s " (DateTime.toShortString connected.ConnectedAt.LocalDateTime)

        createStatusTableCell "Verbunden" StatusDot.Green (Some tooltip)
    | Some(Disconnected disconnected) ->
        let tooltip =
            match disconnected.DisconnectedAt with
            | Some disconnectedAt -> sprintf "Seit %s " (DateTime.toShortString disconnectedAt.LocalDateTime)
            | None -> "Schon seit längerem (noch nie?) gibt es keine Verbindung zum Gateway"

        createStatusTableCell "Keine Verbindung" StatusDot.Red (Some tooltip)
    | None -> createStatusTableCell "Fehler" StatusDot.Grey (Some "Fehler beim Laden des Gateway Status")

let private createStatusCell (status: Refreshable<GatewayStatus option>) =
    match status with
    | NotLoaded -> createStatusTableCell "Unbekannt" StatusDot.Grey None
    | Refreshing ->
        Panel.Block.div [] [
            Button.button [ Button.IsLink; Button.IsLoading true ] [ str "Aktueller Status laden" ]
        ]
    | Refreshed data -> createStatusCellFromStatus data

let private gatewayHasValidLocation (gateway: MySensGateway) : bool =
    Location.fromOptional gateway.Longitude gateway.Latitude |> Option.isSome

let gatewayToRow dispatch (users: UserDto list) (index: int) (gateway: GatewayViewModel) =
    let findUser =
        fun userId -> List.tryFind (fun (user: UserDto) -> user.Id = userId) users

    let validLocationLabel =
        if gatewayHasValidLocation gateway.Gateway then
            "Ja"
        else
            "Nein"

    let statusCell = createStatusCell gateway.Status

    tr [] [
        td [] [ Table.rowIndexString index ]
        td [] [ str gateway.Gateway.TtnId ]
        td [] [ str gateway.Gateway.Eui ]
        td [] [ str gateway.Gateway.Name ]
        td [] [
            str (gateway.Gateway.TtsDescription |> Option.defaultValue "")
        ]
        td [] [
            str (gateway.Gateway.Note |> Option.defaultValue "")
        ]
        td [] [ str validLocationLabel ]
        statusCell
        td [] [
            Option.map findUser gateway.Gateway.UserId
            |> Option.flatten
            |> Option.map (fun user -> getFullName user)
            |> Option.defaultValue ""
            |> str
        ]
        td [] [
            Button.button [
                Button.OnClick(fun _ ->
                    dispatch (
                        GatewayListMsg.OpenModal {
                            Id = gateway.Id
                            Value = gateway.Gateway
                        }
                        |> GatewayList
                    )
                )
            ] [ str "Bearbeiten" ]
        ]
    ]

let gatewayListToTable dispatch (gateways: GatewayViewModel list) (users: UserDto list) =
    Table.table [
        Table.IsBordered
        Table.IsFullWidth
        Table.IsStriped
    ] [
        thead [] [
            tr [] [
                th [] [ str "#" ]
                th [] [ str "TTS Id" ]
                th [] [ str "EUI" ]
                th [] [ str "Name" ]
                th [] [ str "TTS Beschreibung" ]
                th [] [ str "Interne Notiz" ]
                th [] [ str "GPS-Position?" ]
                th [ classList [ ("last_date", true) ] ] [ str "Status" ]
                th [] [ str "Zugeordneter Benutzer" ]
                th [] []
            ]
        ]
        tbody
            []
            (gateways
             |> List.sortBy (fun gw -> gw.Gateway.Name)
             |> List.mapi (gatewayToRow dispatch users))
    ]

let refreshGatewayStats isRefreshRequestRunning dispatch =
    Button.button [
        Button.IsLoading isRefreshRequestRunning
        Button.Color IsLink
        Button.OnClick(fun _ -> dispatch (GatewayListMsg.LoadAllGatewayStatus |> GatewayList))
    ] [ str "Status aller Gateways laden" ]

let refreshDevicesTTNButton isRefreshRequestRunning dispatch =
    Button.button [
        Button.IsLoading isRefreshRequestRunning
        Button.Color IsLink
        Button.OnClick(fun _ -> dispatch (GatewayList GatewayListMsg.RefreshGatewaysTTN))
    ] [ str "Gateways aus TTN aktualisieren" ]

let refreshDevicesTTIButton isRefreshRequestRunning dispatch =
    Button.button [
        Button.IsLoading isRefreshRequestRunning
        Button.Color IsLink
        Button.OnClick(fun _ -> dispatch (GatewayList GatewayListMsg.RefreshGatewaysTTI))
    ] [ str "Gateways aus TTI aktualisieren" ]

let tableHeader dispatch model =
    Level.level [] [
        Level.left [] []
        Level.right [] [
            Level.item [] [
                Field.div [] [
                    Control.div [] [
                        refreshGatewayStats model.RefreshAllGatewayStatsRunning dispatch
                    ]
                ]
            ]
            Level.item [] [
                Field.div [] [
                    Control.div [] [
                        refreshDevicesTTNButton model.TTNRefreshRequestRunning dispatch
                    ]
                ]
            ]
            Level.item [] [
                Field.div [] [
                    Control.div [] [
                        refreshDevicesTTIButton model.TTIRefreshRequestRunning dispatch
                    ]
                ]
            ]
        ]
    ]

let private dataView dispatch (data: DataModel) =
    let table = gatewayListToTable dispatch data.Gateways data.Users

    let modal =
        data.Modal
        |> Option.map (Gateway.view dispatch)
        |> Option.defaultValue (div [] [])

    Container.container [ Container.IsFluid ] [
        Heading.h1 [] [ str "MySens Gateway Liste" ]
        tableHeader dispatch data
        Table.scrollableTable table
        modal
    ]

let view (model: Model) dispatch = Loadable.view (dataView dispatch) model