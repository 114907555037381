module Client.Forms.RimPro

open Client.DomainTypes.Msg
open Fable.React
open Fable.React.Props
open Fulma
open Shared
open Shared.Dto
open Shared.DtoTypes.RimPro
open Shared.Infrastructure
open Fable.Core.JsInterop

type Model = {
    Name: string option
    AirSensor: IdValue<MapSensorDto> option
    RainSensor: IdValue<MapSensorDto> option
    FirstWetnessSensor: IdValue<MapSensorDto> option
    SecondWetnessSensor: IdValue<MapSensorDto> option
    AvailableAirSensors: IdValue<MapSensorDto> list
    AvailableRainSensors: IdValue<MapSensorDto> list
    AvailableLeafletWetnessSensors: IdValue<MapSensorDto> list
    RequestRunning: bool
}

let tryCreateDto (model: Model) : NewRimProStationDto option =
    let factory =
        fun
            (name: string)
            (airSensor: IdValue<MapSensorDto>)
            (rainSensor: IdValue<MapSensorDto>)
            (firstWetnessSensor: IdValue<MapSensorDto>) -> {
            NewRimProStationDto.Name = name
            AirSensorId = airSensor.Id
            RainSensorId = rainSensor.Id
            FirstWetnessSensorId = firstWetnessSensor.Id
            SecondWetnessSensorId = model.SecondWetnessSensor |> Option.map (fun sensor -> sensor.Id)
        }

    factory
    |> Some
    |> Option.apply model.Name
    |> Option.apply model.AirSensor
    |> Option.apply model.RainSensor
    |> Option.apply model.FirstWetnessSensor

type FormResult =
    | Noop
    | Create of NewRimProStationDto
    | CloseModal

let init airSensors rainSensors (leafletWetnessSensors: IdValue<MapSensorDto> list) = {
    Name = None
    AirSensor = List.tryHead airSensors
    RainSensor = List.tryHead rainSensors
    FirstWetnessSensor = List.tryHead leafletWetnessSensors |> Option.orElse (List.tryHead airSensors)
    SecondWetnessSensor = None
    AvailableAirSensors = airSensors
    AvailableRainSensors = rainSensors
    AvailableLeafletWetnessSensors = leafletWetnessSensors
    RequestRunning = false
}

let update (msg: RimProFormMsg) (model: Model) =
    match msg with
    | RimProFormMsg.NameUpdated name -> { model with Name = name }, Noop
    | AirSensorUpdated sensor -> { model with AirSensor = sensor }, Noop
    | RainSensorUpdated sensor -> { model with RainSensor = sensor }, Noop
    | FirstWetnessSensorUpdated sensor -> { model with FirstWetnessSensor = sensor }, Noop
    | SecondWetnessSensorUpdated sensor -> { model with SecondWetnessSensor = sensor }, Noop
    | RimProFormMsg.CloseModal -> model, FormResult.CloseModal
    | RimProFormMsg.Create newStation -> { model with RequestRunning = true }, FormResult.Create newStation
    | RimProFormMsg.CreationFinished -> { model with RequestRunning = false }, FormResult.Noop

let private sensorSelect
    onChange
    (selectedSensor: IdValue<MapSensorDto> option)
    (availableSensors: IdValue<MapSensorDto> list)
    (withNoneOption: bool)
    =
    let sensorToOption (sensor: IdValue<MapSensorDto>) =
        option [ Value sensor.Id ] [ str (MapSensor.getBaseData sensor.Value).Name ]

    let findSensor (id: string) =
        availableSensors |> List.tryFind (fun sensor -> sensor.Id = int id)

    let mutable options = (List.map sensorToOption availableSensors)

    if withNoneOption then
        options <- (option [ Value "" ] [ str "Ohne" ]) :: options
    else
        ()

    Select.select [ Select.IsFullWidth ] [
        select
            [
                DefaultValue(
                    selectedSensor
                    |> Option.map (fun selected -> selected.Id.ToString())
                    |> Option.defaultValue ""
                )
                OnChange(fun event -> event.target?value |> String.toOption |> Option.bind findSensor |> onChange)
            ]
            options
    ]

let private formView dispatch (model: Model) =
    let wetnessSensors =
        List.append model.AvailableLeafletWetnessSensors model.AvailableAirSensors

    form [] [
        Field.div [] [
            Label.label [] [ str "Name" ]
            Control.div [] [
                Input.text [
                    model.Name |> Option.defaultValue "" |> Input.Value
                    Input.OnChange(fun event -> event.Value |> String.toOption |> RimProFormMsg.NameUpdated |> dispatch)
                ]
            ]
        ]
        Field.div [] [
            Label.label [] [ str "Temperatur Sensor" ]
            Control.div [] [
                sensorSelect
                    (RimProFormMsg.AirSensorUpdated >> dispatch)
                    model.AirSensor
                    model.AvailableAirSensors
                    false
            ]
        ]
        Field.div [] [
            Label.label [] [ str "Regen Sensor" ]
            Control.div [] [
                sensorSelect
                    (RimProFormMsg.RainSensorUpdated >> dispatch)
                    model.RainSensor
                    model.AvailableRainSensors
                    false
            ]
        ]
        Field.div [] [
            Label.label [] [ str "Blattnässe Sensor 1" ]
            Control.div [] [
                sensorSelect
                    (RimProFormMsg.FirstWetnessSensorUpdated >> dispatch)
                    model.FirstWetnessSensor
                    wetnessSensors
                    false
            ]
        ]

        Field.div [] [
            Label.label [] [ str "Blattnässe Sensor 2 (optional)" ]
            Control.div [] [
                sensorSelect
                    (RimProFormMsg.SecondWetnessSensorUpdated >> dispatch)
                    model.SecondWetnessSensor
                    wetnessSensors
                    true
            ]
        ]
    ]

let private saveButton dispatch (model: Model) =
    let maybeNewStation = tryCreateDto model

    let buttonOptions = [
        Button.IsLoading model.RequestRunning
        Button.Color IsSuccess

        match maybeNewStation with
        | Some newStation -> Button.OnClick(fun _ -> dispatch (RimProFormMsg.Create newStation))
        | None -> Button.Disabled true
    ]

    Button.button buttonOptions [ str "Erstellen" ]

let view (dispatch: RimProFormMsg -> unit) (model: Model) =
    let closeModal = (fun _ -> dispatch RimProFormMsg.CloseModal)

    Modal.modal [ Modal.IsActive true ] [
        Modal.background [] []
        Modal.Card.card [] [
            Modal.Card.head [] [
                Modal.Card.title [] [ str "Neues RimPro Station erstellen" ]
                Delete.delete [ Delete.OnClick closeModal ] []
            ]
            Modal.Card.body [] [ Content.content [] [ formView dispatch model ] ]
            Modal.Card.foot [] [ saveButton dispatch model ]
        ]
    ]