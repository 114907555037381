namespace Shared.Infrastructure

open System.Collections.Generic

[<RequireQualifiedAccess>]
module Dict =
    let tryGetValue (dict: IDictionary<'key, 'value>) (key: 'key) : 'value option =
        if dict.ContainsKey key then Some(dict.Item key) else None

    let values (dict: IDictionary<'key, 'value>) : 'value list = dict.Values |> List.ofSeq

    let ofList (f: 'input -> 'key * 'value) (values: 'input list) : IDictionary<'key, 'value> =
        values |> List.map f |> dict