module Client.Forms.AddPackage


open Client.Domain
open Client.DomainTypes.Msg
open Elmish
open Fable.React.Props
open Fable.Remoting.MsgPack.Write
open Fulma
open Shared.Dto.User
open Fable.React.Standard
open Fable.React.Helpers
open Client.Infrastructure.Api
open Client
open Thoth.Elmish
open Fable.Core.JsInterop

type DataModel = {
    RequestRunning: bool
    UserId: int
    AvailablePackages: Package list
    SelectedPackage: Package
}

type FormResult =
    | Noop
    | CloseModal
    | CloseAndRefresh

type Model = Loadable<DataModel, int>

let init userId availablePackages = {
    RequestRunning = false
    UserId = userId
    AvailablePackages = availablePackages
    SelectedPackage = availablePackages.Head
}

let packageToOption (package: Package) =
    let label = getPackageLabel package
    let value = getPackageId package

    option [ Value value ] [ str label ]

let packageSelect dispatch selected availablePackages =
    let options = List.map packageToOption availablePackages

    Select.select [ Select.IsFullWidth ] [
        select
            [
                DefaultValue(getPackageLabel selected)
                OnChange(fun event ->
                    dispatch (event.target?value |> packageFromId |> PackageSelected |> AddPackage |> UserList)
                )
            ]
            options
    ]


let form dispatch (model: DataModel) =
    form [] [
        Field.div [] [
            Label.label [] [ str "Paket" ]
            Control.div [] [
                packageSelect dispatch model.SelectedPackage model.AvailablePackages
            ]
        ]
    ]

let saveButton dispatch (model: DataModel) =
    let buttonOptions = [
        Button.IsLoading model.RequestRunning
        Button.Color IsSuccess
        Button.OnClick(fun _ ->
            dispatch (
                AddPackageFormMsg.AddPackage(model.UserId, model.SelectedPackage)
                |> AddPackage
                |> UserList
            )
        )
    ]

    Button.button buttonOptions [ str "Hinzufügen" ]

let modalView dispatch (model: DataModel) =
    let closeModal =
        (fun _ -> dispatch (AddPackageFormMsg.CloseModal |> AddPackage |> UserList))

    Modal.modal [ Modal.IsActive true ] [
        Modal.background [] []
        Modal.Card.card [] [
            Modal.Card.head [] [
                Modal.Card.title [] [ str "Neues Paket hinzufügen" ]
                Delete.delete [ Delete.OnClick closeModal ] []
            ]
            Modal.Card.body [] [ Content.content [] [ form dispatch model ] ]
            Modal.Card.foot [] [ saveButton dispatch model ]
        ]
    ]

let update (msg: AddPackageFormMsg) (model: Model) =
    match (msg, model) with
    | AvailablePackagesReceived packages, Loadable.Loading loadingModel ->
        Loadable.Data(init loadingModel packages), Cmd.none, FormResult.Noop
    | PackageSelected package, Loadable.Data data ->
        Loadable.Data { data with SelectedPackage = package }, Cmd.none, FormResult.Noop
    | AddPackageFormMsg.AddPackage(userId, package), Loadable.Data _ ->
        model,
        Cmd.OfAsync.perform api.addPackage (userId, package) (PackageAdded >> AddPackage >> UserList),
        FormResult.Noop
    | PackageAdded success, _ ->
        let toastCmd = Toast.create "Paket erfolgreich hinzugefügt" |> Toast.success

        model, toastCmd, FormResult.CloseAndRefresh
    | AddPackageFormMsg.CloseModal, _ -> model, Cmd.none, FormResult.CloseModal
    | _, _ -> model, Cmd.none, FormResult.Noop


let view dispatch (model: Model) =
    Loadable.view (modalView dispatch) model