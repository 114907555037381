namespace Client.Components

open Browser.Types
open Fable.FontAwesome
open Fulma
open Fable.React

[<RequireQualifiedAccess>]
module ConfirmationModal =
    type Configuration = {
        Headline: string
        Text: string
        OnClose: MouseEvent -> unit
        OnYes: MouseEvent -> unit
        OnNo: MouseEvent -> unit
    }

    let private createButton isLoading text icon color onClick =
        Button.button [
            Button.Color color
            Button.OnClick onClick
            Button.IsLoading isLoading
        ] [
            Icon.icon [] [ Fa.i [ icon ] [] ]
            span [] [ str text ]
        ]

    let private createModalFooter (isLoading: bool) (configuration: Configuration) = [
        Level.level [] [
            Level.left [] []
            Level.right [] [
                Level.item [] [
                    Control.p [] [
                        createButton isLoading "Ja" Fa.Solid.Check IsGrey configuration.OnYes
                    ]
                ]
                Level.item [] [
                    Control.p [] [
                        createButton false "Nein" Fa.Solid.Times IsGrey configuration.OnNo
                    ]
                ]
            ]
        ]
    ]

    let view isLoading configuration =
        let body = [ str configuration.Text ]

        let footer = createModalFooter isLoading configuration

        let props: Modal.ModalProps = {
            OnClose = configuration.OnClose
            Headline = configuration.Headline
            Body = body
            Footer = footer
        }

        Modal.view props