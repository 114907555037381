namespace Shared.Dto

type Location = {
    Longitude: float
    Latitude: float
}

[<RequireQualifiedAccess>]
module Location =

    let create (longitude: float) (latitude: float) : Location option =
        let validatedLatitude =
            if -90. <= latitude && latitude <= 90. then
                Some latitude
            else
                None

        let validatedLongitude =
            if -180. <= longitude && longitude <= 180. then
                Some longitude
            else
                None

        Option.map2
            (fun long lat -> {
                Longitude = long
                Latitude = lat
            })
            validatedLongitude
            validatedLatitude

    let fromOptional (maybeLongitude: float option) (maybeLatitude: float option) : Location option =
        match maybeLongitude, maybeLatitude with
        | Some longitude, Some latitude -> create longitude latitude
        | _, _ -> None