namespace Client.Forms

open System
open Client.Infrastructure.Api
open Client.DomainTypes.Msg
open Elmish
open Fable.React
open Fulma
open Microsoft.FSharp.Core
open Shared.Dto.Dto
open Shared
open Shared.DtoTypes
open Shared.Infrastructure
open Client
open Thoth.Elmish


[<RequireQualifiedAccess>]
module ContactInfo =
    type Model = {
        Session: UserSession
        Name: string option
        Phone: string option
        RequestRunning: bool
    }

    type FormResult =
        | Noop
        | CloseModal
        | CloseAndRefresh

    let private createNewModel session : Model = {
        Session = session
        Name = None
        Phone = None
        RequestRunning = false
    }

    let init session = createNewModel session

    let private createContactInfo userId name phone : AlertDto.NewContactInfo = {
        Name = name
        UserId = userId
        Data = AlertDto.Phone phone
    }

    let private characterValidForPhoneNumber character =
        Char.IsDigit character || character = '+'

    let createContactInfoMsg userId maybeName (maybePhone: string option) =
        let validatedPhone =
            maybePhone
            |> Option.map Seq.toList
            |> Option.bind (fun phone ->
                if List.forall characterValidForPhoneNumber phone then
                    Some phone
                else
                    None
            )
            |> Option.map String.Concat

        Some createContactInfo
        |> Option.apply (Some userId)
        |> Option.apply maybeName
        |> Option.apply validatedPhone
        |> Option.map ContactInfoFormMsg.CreateContactInfo

    let form dispatch (model: Model) =
        form [] [
            Field.div [] [
                Label.label [] [ str "Name" ]
                Control.div [] [
                    Input.text [
                        model.Name |> Option.defaultValue "" |> Input.Value
                        Input.OnChange(fun event ->
                            event.Value |> String.toOption |> ContactInfoFormMsg.NameChanged |> dispatch
                        )
                    ]
                ]
            ]
            Field.div [] [
                Label.label [] [ str "Telefonnummer" ]
                Control.div [] [
                    Input.text [
                        Input.Placeholder "00431234567890"
                        model.Phone |> Option.defaultValue "" |> Input.Value
                        Input.OnChange(fun event ->
                            event.Value |> String.toOption |> ContactInfoFormMsg.PhoneChanged |> dispatch
                        )
                    ]
                    Help.help [] [
                        str "Bitte gib die Telefonnummer mit der Ländervorwahl ein. Beispiel: 0043664123456789"
                    ]
                ]
            ]
        ]

    let saveButton dispatch (model: Model) =
        let maybeOnClick =
            createContactInfoMsg model.Session.User.Id model.Name model.Phone
            |> Option.map (fun msg -> Button.OnClick(fun _ -> dispatch msg))

        let buttonOptions = [
            Button.IsLoading model.RequestRunning
            Button.Color IsSuccess
            Button.Disabled(Option.isNone maybeOnClick)
        ]

        Button.button (List.addToListIfSome buttonOptions maybeOnClick) [ str "Speichern" ]


    let view (dispatch: ContactInfoFormMsg -> unit) (model: Model) =
        let closeModal = (fun _ -> dispatch ContactInfoFormMsg.CloseModal)

        let headline = "Neue Kontaktdaten anlegen"

        Modal.modal [ Modal.IsActive true ] [
            Modal.background [] []
            Modal.Card.card [] [
                Modal.Card.head [] [
                    Modal.Card.title [] [ str headline ]
                    Delete.delete [ Delete.OnClick closeModal ] []
                ]
                Modal.Card.body [] [ Content.content [] [ form dispatch model ] ]
                Modal.Card.foot [] [ saveButton dispatch model ]
            ]
        ]

    let update (msg: ContactInfoFormMsg) (model: Model) =
        match msg with
        | ContactInfoFormMsg.NameChanged name -> { model with Name = name }, Cmd.none, FormResult.Noop
        | PhoneChanged phone -> { model with Phone = phone }, Cmd.none, FormResult.Noop
        | CreateContactInfo contactInfo ->
            let request = {
                SessionKey = model.Session.SessionKey
                Data = contactInfo
            }

            { model with RequestRunning = true },
            Cmd.OfAsync.perform api.createContactInfo request ContactInfoCreated,
            FormResult.Noop
        | ContactInfoCreated response ->
            match response with
            | Ok _ ->
                let toastCmd = Toast.create "Speichern erfolgreich" |> Toast.success

                model, toastCmd, FormResult.CloseAndRefresh
            | Error _ ->
                let toastCmd = Toast.create "Fehler beim Speichern" |> Toast.error

                model, toastCmd, FormResult.Noop
        | ContactInfoFormMsg.CloseModal -> model, Cmd.none, FormResult.CloseModal