[<RequireQualifiedAccess>]
module Client.Components.Modal

open Browser.Types
open Fable.React
open Fulma

type ModalProps = {
    OnClose: MouseEvent -> unit
    Headline: string
    Body: ReactElement list
    Footer: ReactElement list
}

let create = {
    OnClose = fun _ -> ()
    Headline = ""
    Body = []
    Footer = []
}

let onClose (f: MouseEvent -> unit) props = { props with OnClose = f }

let headline (headline: string) props = { props with Headline = headline }

let body (body: ReactElement list) props = { props with Body = body }

let footer (footer: ReactElement list) props = { props with Footer = footer }

let view (props: ModalProps) =
    Modal.modal [ Modal.IsActive true ] [
        Modal.background [] []
        Modal.Card.card [] [
            Modal.Card.head [] [
                Modal.Card.title [] [ str props.Headline ]
                Delete.delete [ Delete.OnClick props.OnClose ] []
            ]
            Modal.Card.body [] [ Content.content [] props.Body ]
            Modal.Card.foot [] props.Footer
        ]
    ]